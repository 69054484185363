<template>
    <div class="data-container">
        <div class="data-head">{{ data && data.title }}</div>
        <el-row class="statistics-head">
            <el-col :span="12">
                <div class="statistics-item">
                    <div class="num">{{ data && data.totalStockNum || 0 }}</div>
                    <div class="name">库存总票数</div>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="statistics-item">
                    <div class="num">{{ data && data.totalSoldNum || 0 }}</div>
                    <div class="name">演出总售票</div>
                </div>
            </el-col>
        </el-row>
        <div class="box MT20">
            <el-radio-group v-model="ticketId" @change="getData">
                <el-radio-button label="">总销售</el-radio-button>
                <el-radio-button v-for="(item) in ticketList" :key="item.name" :label="item.name">{{ item.value }}</el-radio-button>
            </el-radio-group>
            <el-row :gutter="20" class="MT20">
                <el-col :lg="8" class="group">
                    <!-- <div class="box">
                        <div class="box-title">
                            <h4>库存总票数</h4>
                        </div>
                        <div class="box-content MT20">
                            <div v-if="data" class="info">
                                <div class="num">{{ data.totalStockNum }} <span>张</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="box MT20">
                        <div class="box-title">
                            <h4>演出总售票</h4>
                        </div>
                        <div class="box-content MT20">
                            <div v-if="data" class="info">
                                <div class="num">{{ data.totalSoldNum }} <span>张</span></div>
                            </div>
                        </div>
                    </div> -->
                    <div class=" list-box1">
                        <div class="box-title">
                            <h4>销售明细</h4>
                        </div>
                        <div v-if="data && data.pageResult" class="list-wrap">
                            <el-table border :data="data.pageResult.result" size="mini" style="width: 100%; height: calc(100% - 50px);">
                                <el-table-column prop="keyStr" label="销售时间"></el-table-column>
                                <el-table-column prop="num" label="销售票数"></el-table-column>
                            </el-table>
                            <div class="page-pagination">
                                <el-pagination
                                    @current-change="getData"
                                    :current-page.sync="pageNo"
                                    :page-size.sync="pageSize"
                                    layout="prev, pager, next"
                                    :total="data.pageResult.totalCount || 0"
                                    background
                                    small>
                                </el-pagination>
                        </div>
                        </div>
                    </div>
                </el-col>
                <el-col :lg="16" class="group">
                    <div class=" chart-box">
                        <div class="box-title">
                            <h4>销售趋势</h4>
                        </div>
                        <div class="chart-wrap MT20">
                            <div id="chart1"></div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        
    </div>
</template>
<script>
import { Chart } from '@antv/g2';
import cookie from 'js-cookie'
export default {
    data() {
        return {
            pageNo:1,
            pageSize: 10,
            dataLoading: null,
            duration: 60000,

            chart1: null,
            chart1Data: [],

            data: null,

            timer: null,

            ticketId: "",
            ticketList: []
        };
    },
    created() {
        this.dataLoading = this.$loading({
            lock: true,
            text: '加载中...',
            background: 'rgba(225, 225, 225, 0.9)'
        });
    },
    mounted() {
        this.getData();
    },
    destroyed(){
        clearTimeout(this.timer);
        if( this.chart1 ){
            this.chart1.destroy();
            this.chart1 = null;
        }
    },
    methods: {
        //获取数据
        async getData() {

            const res = await this.$request({
                url: "/daping/sell/data",
                method: "POST",
                params: {
                    secretKey: cookie.get('entryDataSecretKey'),
                    pageNo: this.pageNo,
                    pageSize: this.pageSize,
                    ticketId: this.ticketId
                },
            }).finally(() => {
                this.dataLoading.close();
            })

            const { state, msg, result } = res.data;
            if (state && result) {

                const _chart1Data = [...result?.pageResult?.result]
                if( _chart1Data && _chart1Data.length > 0){
                    this.chart1Data = _chart1Data.reverse();
                }
                
                if (this.chart1) {
                    this.chart1.changeData(this.chart1Data);
                } else {
                    this.drawChart1();
                }

                this.data = result;
                if( !this.ticketId ){
                    this.ticketList = result.ticketList;
                }

            } else {
                if (state == 'daping.tologin') {
                    this.$router.replace('/entryData/login')
                } else {
                    this.$message.error(msg || '请求错误');
                }
            }

            this.timer = setTimeout(() => {
                this.pageNo = 1;
                this.getData();
                clearTimeout(this.timer);
            }, this.duration);
        },

        //绘制图标
        drawChart1() {
            this.chart1 = new Chart({
                container: 'chart1',
                autoFit: true,
                height: 300,
                appendPadding: [20, 0, 10, 0],
                // theme: {
                //     styleSheet: {
                //         backgroundColor: '#000000',
                //     }
                // },
                fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff',
            });
            this.chart1.data(this.chart1Data);

            this.chart1.scale({
                'keyStr': {
                    range: [0, 1],
                    tickCount: 10,
                },
                'num': {
                    alias: '销售票数',
                    nice: true,
                }
            });

            // this.chart1.axis('num', {
            //     grid: {
            //         line: {
            //             style: {
            //                 stroke: '#444444'
            //             },
            //         },
            //     },
            // })
            // this.chart1.axis('keyStr', {
            //     line: {
            //         style: {
            //             stroke: '#444444'
            //         },
            //     },
            //     tickLine: {
            //         style: {
            //             stroke: '#444444'
            //         },
            //     }
            // })

            this.chart1.tooltip({
                showCrosshairs: true,
                crosshairs: {
                    line: {
                        style: {
                            stroke: '#444444'
                        },
                    },
                }
            });

            this.chart1
                .line()
                .position('keyStr*num')
                .style({
                    stroke: 'l(0) 0:#3969FF 0.5:#7ec2f3 1:#00FDEB',
                })
            // this.chart3.point().position('name*count').style({
            //     fill: "#000000"
            // });

            this.chart1.render();
        }
    },
}
</script>
<style lang="scss" scoped>
.data-container {
    padding: 0 20px 20px 20px;
    background: #f8f8f8;
    min-height: 100vh;

    .data-head {
        font-size: 24px;
        line-height: 1;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        // color: #fff;
        margin: 0 -20px 20px -20px;
        padding: 0 20px;
        text-align: center;
    }

    .statistics-head{
        background: #fff;
        border-radius: 10px;
        .statistics-item{
            height: 90px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .num{
                font-size: 16px;
                font-weight: 600;
                color: $--color-theme;
            }
            .name{
                font-size: 12px;
                margin-top: 5px;
            }
        }
    }

    .group {
        height: calc(100vh - 330px);
        // min-height: 1000px;
        // margin-bottom: 20px;
        display: flex;
        flex-direction: column;
    }

    .box {
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
        padding: 20px;
        // color: #fff;
        box-shadow: 0px 10px 40px 0px rgba(172, 174, 186, 0.1);
        display: flex;
        flex-direction: column;
        .box-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 1;

            h4 {
                font-size: 16px;
                font-weight: 600;
            }
        }

        .box-content {
            .info {
                .num {
                    font-size: 30px;
                    font-weight: 600;
                    span{
                        font-size: 14px;
                        color: #999;
                    }
                }
            }
        }
    }


    .list-box1,.chart-box {
        flex: 1;
    }

    .list-wrap {
        margin-top: 20px;
        flex: 1;
        display: flex;
        flex-direction: column;
        .page-pagination{
            text-align: center;
            margin-top: 20px;
        }
    }
    .chart-box{
        display: flex;
        flex-direction: column;
    }
    .chart-wrap{
        flex:1;
        #chart1{
            height: 100%;
        }
    }

    @media screen and (max-width: 768px) {
        .data-head {
            font-size: 16px;
            line-height: 1.2;
        }

        .group {
            height: auto;
            min-height: auto;
        }

        .box {
            padding: 20px 10px;
            overflow: visible;
        }

        .list-wrap {
            height: auto;
        }
    }

}
</style>